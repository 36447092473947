import React from "react";

function IndexBottom() {
    return (
        <>

            <div id="bottom-wrap">
                <div>
                    <div>
                        <span className="label-name need-space">地点</span>
                        <span>北京市东城区报房胡同40号</span>
                    </div>
                    <div>
                        <span className="label-name need-space">预约</span>
                        <span>关注微信公众号 SunS Living Gallery</span>
                    </div>
                    <div>
                        <span className="label-name">联系方式</span>
                        <span>contact@suns.art</span>
                    </div>
                </div>
            </div>

            <div id="icp-wrap">
                ©2022 SunS Living Gallery.
                <a className="icp-link" target="_blank" rel="noreferrer" href="https://beian.miit.gov.cn/">京ICP备2022029872号</a>
                All Rights Reserved.
            </div>
        </>
    );
}

export default IndexBottom;
