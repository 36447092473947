/*eslint-disable*/
import React from "react";
const items = [
    {src: "https://cdn.suns.art/photo/swipe/index/1.png?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/index/2.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/index/3.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/index/4.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/index/5.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/index/6.jpg?x-oss-process=style/1080",},
]

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation} from "swiper";
import IndexTitle from "./IndexTitle";
import IndexBottom from "./IndexBottom";

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

export default () => {
    return (
        <>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                centeredSlides={true}
                autoplay={{
                    delay: 8000,
                    disableOnInteraction: false,
                }}
                loop={true}
                preloadImages={true}
                navigation={true}
                modules={[Autoplay, Navigation]}
            >
                <IndexTitle/>
                {/*<IndexBottom/>*/}

                {items.map((item) => {
                    return (
                        <SwiperSlide key={item.src}>
                            <div
                                className="index-header-carousel-image carousel-image"
                                style={{
                                    backgroundImage: "url(" + item.src + ")"
                                }}
                            ></div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </>
    );
};
