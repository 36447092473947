import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit

// import IndexDemo from "views/deprecated/Index";
// import NucleoIcons from "views/deprecated/NucleoIcons.js";
// import LoginPage from "views/examples/LoginPage.js";
// import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";

import Index from "views/Index";
import About from "views/About";
import Case from "views/Case";
import Contact from "views/Contact";

import "assets/scss/index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route path="/about" render={(props) => <About {...props} />} />
        <Route path="/case" render={(props) => <Case {...props} />} />
        <Route path="/contact" render={(props) => <Contact {...props} />} />
        {/*<Route*/}
        {/*  path="/nucleo-icons"*/}
        {/*  render={(props) => <NucleoIcons {...props} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/landing-page"*/}
        {/*  render={(props) => <LandingPage {...props} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/profile-page"*/}
        {/*  render={(props) => <ProfilePage {...props} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/login-page"*/}
        {/*  render={(props) => <LoginPage {...props} />}*/}
        {/*/>*/}
        {/*<Route path="/index-demo" render={(props) => <IndexDemo {...props} />} />*/}

        <Route path="/" render={(props) => <Index {...props} />} />
        <Redirect to="/" />
        {/*<Redirect from="/" to="/index" />*/}
      </Switch>
    </Switch>
  </BrowserRouter>
);
