import React from "react";

function CaseLabel(data) {

    return (
        <div className="case-label-wrap">
            <span className="case-label-text-wrap">
                <span className="case-label-number">{data.number}</span>
                <span className="case-label">{data.label}</span>
            </span>
            <div className="case-label-border"></div>
        </div>
    );
}

export default CaseLabel;
