import React from "react";

// reactstrap components
import {
    Button,
    Input,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col, InputGroupAddon, FormGroup, Label, Modal, ModalBody
} from "reactstrap";
import axios from "axios";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/Headers/PageHeader.js";

import "assets/scss/about.scss";
import "assets/scss/case.scss";
import "assets/scss/contact.scss";

const items = [
    {src: "https://cdn.suns.art/photo/contact/banner.png",},
]

const title = ""

function Case() {
    const [nameFocus, setNameFocus] = React.useState(false);
    const [jobFocus, setJobFocus] = React.useState(false);
    const [genderFocus, setGenderFocus] = React.useState(false);
    const [mobileFocus, setMobileFocus] = React.useState(false);
    const [wxFocus, setWxFocus] = React.useState(false);
    const [numberFocus, setNumberFocus] = React.useState(false);
    const [dateFocus, setDateFocus] = React.useState(false);
    const [timeFocus, setTimeFocus] = React.useState(false);

    const [modal, setModal] = React.useState(false);


    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.body.classList.add("menu-on-left");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    let handleSubmit = (event) => {
        event.preventDefault();      //阻止表单提交
        let oForm = document.getElementById('form');
        let query = serializeForm(oForm);

        axios.get('/mail?' + query)
            .then(function (response) {
                setModal(true);
            })
            .catch(function (error) {

            });
    }

    let closeModal = () => {
        setModal(false);
        window.location.href = "/";
    }

    function serializeForm(form) {
        if (!form || form.tagName.toUpperCase() !== 'FORM') {
            return false;
        }
        var res = [];
        var tag, tagType, tagVal, tagName;
        for (var i = 0; i < form.length; i++) {
            tag = form[i];
            console.log(tag)
            tagType = form[i].type;
            tagVal = form[i].value;
            tagName = form[i].name;
            if (!tagName) {
                continue;
            }
            var reg1 = /textarea|text|passsword|label|date/g;
            var reg2 = /radio|checkbox/g;
            var reg3 = /select|select-one/g;
            if (reg1.test(tagType)) {
                res.push((tagName) + "=" + (tagVal));
            } else if (reg2.test(tagType) && tag.checked) {
                res.push((tagName) + "=" + (tagVal));
                console.log(2)
            } else if (reg3.test(tagType)) {
                for (var j = 0; j < tag.options.length; j++) {
                    if (tag.options[j].selected) {
                        res.push((tagName) + "=" + (tag.options[j].value || tag.options[j].text));
                    }
                }
                console.log(3)
            } else {
                console.log(4)
            }
        }
        return res.join("&");
    }

    return (
        <>
            <IndexNavbar/>
            <div className="wrapper">
                <PageHeader items={items} title={title}/>
                <div className="section section-about-us">
                    <Container>
                        <Col lg={{offset: 2, size: 8}}>
                            <form id="form" onSubmit={handleSubmit}>
                                <Row>
                                    <Col lg="6">
                                        <InputGroup className={nameFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-solid fa-signature"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="姓名"
                                                type="text"
                                                name="name"
                                                onFocus={() => setNameFocus(true)}
                                                onBlur={() => setNameFocus(false)}
                                            ></Input>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6">
                                        <InputGroup className={genderFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-solid fa-person-half-dress"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="gender"
                                                type="select"
                                                placeholder="性别"
                                                defaultValue="性别"
                                                onFocus={() => setGenderFocus(true)}
                                                onBlur={() => setGenderFocus(false)}
                                            >
                                                <option disabled>性别</option>
                                                <option>男</option>
                                                <option>女</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <InputGroup className={mobileFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-solid fa-mobile-screen-button"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="手机"
                                                type="number"
                                                name={"phone"}
                                                onFocus={() => setMobileFocus(true)}
                                                onBlur={() => setMobileFocus(false)}
                                            ></Input>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6">
                                        <InputGroup className={jobFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-solid fa-user-doctor"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="职业"
                                                type="text"
                                                name={"job"}
                                                onFocus={() => setJobFocus(true)}
                                                onBlur={() => setJobFocus(false)}
                                            ></Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <InputGroup className={wxFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-brands fa-weixin"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="微信号"
                                                type="text"
                                                name={"wx"}
                                                onFocus={() => setWxFocus(true)}
                                                onBlur={() => setWxFocus(false)}
                                            ></Input>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6">
                                        <InputGroup className={numberFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-solid fa-people-group"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="number"
                                                type="select"
                                                placeholder="参观人数"
                                                defaultValue="参观人数"
                                                onFocus={() => setNumberFocus(true)}
                                                onBlur={() => setNumberFocus(false)}
                                            >
                                                <option disabled>参观人数</option>
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <InputGroup className={dateFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-regular fa-calendar"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="参观日期"
                                                type="date"
                                                name="date"
                                                onFocus={() => setDateFocus(true)}
                                                onBlur={() => setDateFocus(false)}
                                            ></Input>
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6">
                                        <InputGroup className={timeFocus ? "input-group-focus" : ""}>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa-regular fa-clock"></i>
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="time"
                                                type="select"
                                                placeholder="参观时间"
                                                defaultValue="参观时间"
                                                onFocus={() => setTimeFocus(true)}
                                                onBlur={() => setTimeFocus(false)}
                                            >
                                                <option disabled>参观时间</option>
                                                <option>10:30-11:30</option>
                                                <option>13:00-14:00</option>
                                                <option>14:00-15:00</option>
                                                <option>15:00-16:00</option>
                                                <option>16:00-17:00</option>
                                                <option>17:00-18:00</option>
                                            </Input>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <InputGroup>
                                            <Input
                                                name="special"
                                                type="textarea"
                                                placeholder="是否有想特别想了解的家居品牌/产品？"
                                            />
                                        </InputGroup>
                                    </Col>
                                    <Col lg="6">
                                        <p className="category">您是从哪里了解我们的？</p>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="微信"></Input>
                                                <span className="form-check-sign"></span>
                                                微信
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="微博"></Input>
                                                <span className="form-check-sign"></span>
                                                微博
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="小红书"></Input>
                                                <span className="form-check-sign"></span>
                                                小红书
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="大众点评"></Input>
                                                <span className="form-check-sign"></span>
                                                大众点评
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="设计师"></Input>
                                                <span className="form-check-sign"></span>
                                                设计师
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="杂志媒体"></Input>
                                                <span className="form-check-sign"></span>
                                                杂志媒体
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="亲戚朋友"></Input>
                                                <span className="form-check-sign"></span>
                                                亲戚朋友
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox" name="from" value="其他"></Input>
                                                <span className="form-check-sign"></span>
                                                其他
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className={"separator"}></div>
                                <Row>
                                    <Col lg={{offset: 4, size: 4}} className={"text-center"}>
                                        <Button color="primary" size="lg" className={"submit-button"}>
                                            提交
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </Col>
                    </Container>
                </div>

                <Modal
                    modalClassName="modal-mini modal-info"
                    toggle={closeModal}
                    isOpen={modal}
                >
                    <div className="modal-header justify-content-center">
                        <div className="modal-profile">
                            <i className="now-ui-icons ui-1_check"></i>
                        </div>
                    </div>
                    <ModalBody>
                        <p>提交成功</p>
                    </ModalBody>
                    <div className="modal-footer">
                        <Button
                            className="btn-neutral"
                            color="link"
                            type="button"
                            onClick={closeModal}
                        >
                            确认
                        </Button>
                    </div>
                </Modal>

                <DefaultFooter/>
            </div>
        </>
    );
}

export default Case;
