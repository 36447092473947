/*eslint-disable*/
import React from "react";

// reactstrap components
import {Container} from "reactstrap";

// core components

function DefaultFooter() {
    return (
        <>
            <footer className="footer footer-default">
                <Container>
                    <div id="dark-footer-wrapper">
                        <div>
                            <div>
                                <div>
                                    <span className="label-name need-space">地点</span>
                                    <span>北京市东城区报房胡同40号</span>
                                </div>
                                <div>
                                    <span className="label-name need-space">预约</span>
                                    <span>关注微信公众号 SunS Living Gallery</span>
                                </div>
                                <div>
                                    <span className="label-name">联系方式</span>
                                    <span>contact@suns.art</span>
                                </div>
                            </div>
                        </div>
                        <div id="copyright-wrap">
                            <div>©2022 SunS Living Gallery</div>
                            <div><a className="icp-link" target="_blank"
                                    href="https://beian.miit.gov.cn/">京ICP备2022029872号</a></div>
                            <div>All Rights Reserved</div>
                        </div>
                    </div>
                </Container>
            </footer>
        </>
    );
}

export default DefaultFooter;
