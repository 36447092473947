import React from "react";

// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";
// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import PageHeader from "components/Headers/PageHeader.js";
import CaseLabel from "../components/CaseLabel";

import "assets/scss/case.scss";

const items = [
    {src: "https://cdn.suns.art/photo/swipe/case/1.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/case/2.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/case/3.jpg?x-oss-process=style/1080",},
    {src: "https://cdn.suns.art/photo/swipe/case/4.jpg?x-oss-process=style/1080",},
]

const title = "项目案例"

function Case() {
    React.useEffect(() => {
        document.body.classList.add("landing-page");
        document.body.classList.add("sidebar-collapse");
        document.body.classList.add("menu-on-left");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("landing-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <IndexNavbar/>
            <div className="wrapper">
                <PageHeader items={items} title={title}/>
                <div className="section section-about-us">
                    <Container>
                        <Row>
                            <Col>
                                <img src="https://cdn.suns.art/photo/case/villa/1.jpg?x-oss-process=style/1080" alt="case-image"/>
                                <h2 className="image-title">只此斑斓 | 借阳光与晚霞</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h4 className="description">
                                    设计公司：SunS Living Gallery<br/>
                                    空间面积：600 ㎡
                                </h4>
                                <h4 className="description">
                                    这是坚果儿为自己设计的自宅，为了找到归属感和舒适感让心灵和空间共鸣，<br/>
                                    她用自己独有的设计方式打造了这个与她性格相仿的住宅。
                                </h4>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/villa/2.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/villa/3.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/villa/4.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/villa/5.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="1" label="借阳光和晚霞"/>
                                    <p>为达到延伸内外的极致居住体验，在这座住宅中，“借景”成了最主要的设计手法。设计师把一层打开，设置更多、更大的窗户，和自然取得连接。放眼望去，空间被葱郁的植被与阳光环绕，客厅镜子则仿佛一抹温柔绚烂的晚霞，让人产生身居自然的微妙错觉，创造更开阔的视野。这处居所“有阳光借阳光，有晚霞借晚霞”。</p>
                                </Col>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="2" label="家中艺术馆"/>
                                    <p>富有设计感的家具和艺术品是这个家的独特之处，墙上没有大面积的大理石，而是为艺术作品留下空间。从玄关开始，艺术家刘毅的《春语者》，Liaigre的金色台灯；到客厅Chester Moon月球表面沙发，Ikon Kobenhavn的黑白格瓷砖桌；再到二层艺术家杨子的《季节花》，楷茳的《流动的风景》，Vipanee的My Bruce Lee …… 艺术无处不在，创造出环绕内在一步一景的独特艺术景象。</p>
                                </Col>
                            </Col>
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/villa/6.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/villa/7.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="3" label="洒满色彩与质感"/>
                                    <p>不同颜色和材质相互拼接、互借个性，为这个家提供归属感。客厅干净的底色和落霞般的镜中世界形成强烈的色彩对比，楼梯间的红蓝对撞标志着空间功能即将分隔；二层空间大胆使用红色，卧室的背景墙和瑜伽房间的吊顶相互呼应。材质上，全屋木材与石材、不同纹理的石材之间相互拼接，这些看似不可能的工艺，达到了只属于“本我”的效果。</p>
                                </Col>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <div className="separator"></div>
                        <div className="case-separator"></div>
                        <div className="separator"></div>
                        <Row>
                            <Col>
                                <img src="https://cdn.suns.art/photo/case/jsl/1.jpg?x-oss-process=style/1080" alt="case-image"/>
                                <h2 className="image-title">素山 | 拾山间一隅</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-auto mr-auto text-center" md="8">
                                <h4 className="description">
                                    设计公司：SunS Living Gallery<br/>
                                    空间面积：270 ㎡
                                </h4>
                                <h4 className="description">
                                    金山岭的阳光和树影照在一座朴素的红砖墙上，透过窗子是平滑的暖灰色调和干净简洁的室内布置，显露着主人悠然自得的生活方式。
                                </h4>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/jsl/2.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/jsl/3.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                            <Col md="4">
                                <img src="https://cdn.suns.art/photo/case/jsl/4.jpg?x-oss-process=style/540v_43" alt="case-image"/>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/jsl/5.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="1" label="山间"/>
                                    <p>为了达到屋主所希望如同“山川延伸到屋内”的感受，设计师采用了具有肌理感的艺术漆和大理石地面进行衔接，让空间展示出了游于山川内的通透感。客厅是黑色的沉稳，在阳光中显示着生活的质感，壁炉带来山间野营的温暖。开放厨房和餐厅则是白色的纯净，天光照射厨房空间为烹饪提供山川上的户外感。远离忙碌的都市，这里是一种逃离规矩之外松弛的野奢感受。</p>
                                </Col>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="2" label="山景"/>
                                    <p>居于山间，将连绵的山景借到室内是很巧妙的一种手法，这不仅将窗户开的更大、更多，而且要开的巧妙，框住最美的景色。家中的每个卧室都有一张别样的山景，让人与山对望，浴室、卫生间和书房同样框出山景，将山脉化为家中的壁画。无论在家的哪一处，抬头望去都有“悠然见南山”的清雅舒展之感受。</p>
                                </Col>
                            </Col>
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/jsl/6.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                        </Row>
                        <div className="separator"></div>
                        <Row className="vertical-center">
                            <Col md={{size: 5, offset: 0}}>
                                <img
                                    src="https://cdn.suns.art/photo/case/jsl/7.jpg?x-oss-process=style/720"
                                    className="article-image" alt="case-image"/>
                            </Col>
                            <Col md={{size: 7, offset: 0}}>
                                <Col md={{size: 10, offset: 1}}>
                                    <CaseLabel number="3" label="山居"/>
                                    <p>房中的装饰和家具也是对山中场景的打造，花器、香氛让这座山中屋从是视觉和嗅觉中换发生机。白发一雄富有表现力的画作成为空间内墙面的景色，为家中增添艺术氛围，也强调了主人沉稳如山的气质。智能化的家居设计，让老人和孩子都可以方便的调节屋内的光感和温度，提供绝佳的野奢居住体验。</p>
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <DefaultFooter/>
            </div>
        </>
    );
}

export default Case;
