import React from "react";

// reactstrap components
import {Container} from "reactstrap";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from "swiper";
import 'swiper/css';

function ArticleSwiper(data) {
    let items = data.items;
    let autoplayObj = {
        delay: 8000,
        disableOnInteraction: false,
    }
    if (items.length === 1) {
        autoplayObj = false;
    }
    return (
        <>
            <div className="article-carousel-wrap">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    centeredSlides={true}
                    autoplay={autoplayObj}
                    loop={true}
                    preloadImages={true}
                    modules={[Autoplay]}
                >
                    {items.map((item) => {
                        return (
                            <SwiperSlide key={item}>
                                <div
                                    className="article-carousel-image carousel-image"
                                    style={{
                                        backgroundImage: "url(" + item + ")"
                                    }}
                                ></div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>

                <div className="content-center">
                    <Container>
                        <h1 className="title"
                            dangerouslySetInnerHTML={{__html: data.title}}></h1>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default ArticleSwiper;
